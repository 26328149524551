import Vue from 'vue';
import Toasted from 'vue-toasted';
Vue.use(Toasted, { iconPack: 'custom-class' })
import ToastMessageComponent from "./components/ToastMessageComponent";
const adminuniversal = new Vue({
    el: '#flash-alerts',
    components: {
        ToastMessageComponent
    }
});
